@use "sass:math";
@use "sass:meta";
@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;
@use "./variables.scss" as *;
@use "../tooltip/_variables.scss" as *;

@mixin kendo-dataviz--layout-base() {

    // Barcode
    .k-barcode {
        display: inline-block;

        > div {
            height: 150px;
        }
    }


    // Exported variables
    // stylelint-disable selector-class-pattern
    .k-var--chart-font {
        font-size: $kendo-chart-font-size;
    }

    .k-var--chart-title-font {
        font-size: $kendo-chart-title-font-size;
    }

    .k-var--chart-pane-title-font {
        font-size: $kendo-chart-pane-title-font-size;
        font-weight: $kendo-chart-pane-title-font-weight;
    }

    .k-var--chart-label-font {
        font-size: $kendo-chart-label-font-size;
    }
    // stylelint-enable selector-class-pattern

    // Elements
    .k-chart,
    .k-sparkline,
    .k-stockchart {
        border-width: $kendo-chart-border-width;
        border-style: solid;
        box-sizing: border-box;
        outline: 0;
        font-family: $kendo-chart-font-family;
        font-size: $kendo-chart-font-size;
        line-height: $kendo-chart-line-height;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;
    }

    .k-chart,
    .k-stockchart {
        display: block;
        height: 400px;
    }

    .k-chart-surface {
        height: 100%;
    }

    .k-chart .k-popup {
        border-width: 0;
    }

    .k-chart-tooltip-wrapper .k-animation-container-shown,
    .k-chart-tooltip-wrapper.k-animation-container-shown {
        transition: $kendo-chart-tooltip-transition;
    }

    .k-sparkline-tooltip-wrapper,
    .k-chart-tooltip-wrapper {
        z-index: 12000;

        .k-popup {
            padding: 0;
            border-width: 0;
            background: transparent;
        }
    }

    .k-chart-tooltip table {
        border-spacing: 0;
        border-collapse: collapse;
    }

    .k-chart-tooltip {
        @include border-radius( k-border-radius(md) );
        font-size: $kendo-chart-tooltip-font-size;
        line-height: var( --kendo-line-height, normal );
        padding-block: $kendo-tooltip-padding-y;
        padding-inline: $kendo-tooltip-padding-x;
    }

    .k-chart-tooltip th {
        width: auto;
        text-align: center;
        padding: 1px;
    }

    .k-chart-tooltip td {
        width: auto;
        text-align: start;
        padding-block: $kendo-chart-tooltip-padding-y;
        padding-inline: $kendo-chart-tooltip-padding-x;
        line-height: var( --kendo-line-height, normal );
        vertical-align: middle;
    }

    .k-chart-crosshair-tooltip,
    .k-chart-shared-tooltip {
        border-width: 1px;
        border-style: solid;
    }

    .k-chart-shared-tooltip .k-chart-shared-tooltip-marker {
        display: block;
        width: 15px;
        height: 3px;
        vertical-align: middle;
    }

    // Selection
    .k-selector {
        position: absolute;
        transform: translateZ(0);
    }

    .k-selection {
        position: absolute;
        height: 100%;
        border-width: 1px;
        border-style: solid;
        border-bottom: 0;
    }

    .k-selection-bg {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .k-handle {
        @include border-radius( 50% );
        width: $kendo-chart-selection-handle-size;
        height: $kendo-chart-selection-handle-size;
        border-width: 1px;
        border-style: solid;
        z-index: 1;
        position: absolute;
        box-sizing: content-box;
    }

    .k-handle div {
        width: 100%;
        height: 100%;
    }

    .k-left-handle {
        left: - math.div( $kendo-chart-selection-handle-size, 2 );
    }

    .k-right-handle {
        right: - math.div( $kendo-chart-selection-handle-size, 2 );
    }

    .k-left-handle div {
        margin: -($kendo-chart-selection-handle-size) 0 0 (- math.div( $kendo-chart-selection-handle-size, 1.5 ));
        padding: ($kendo-chart-selection-handle-size * 2) (math.div( $kendo-chart-selection-handle-size, 1.5 ) * 2) 0 0;
    }

    .k-right-handle div {
        margin: -($kendo-chart-selection-handle-size) 0 0 (- math.div( $kendo-chart-selection-handle-size, 1.5 ));
        padding: ($kendo-chart-selection-handle-size * 2) 0 0 (math.div( $kendo-chart-selection-handle-size, 1.5 ) * 2);
    }

    .k-left-handle.k-handle-active div {
        margin-left: -($kendo-chart-selection-handle-size * 2);
        padding-left: ($kendo-chart-selection-handle-size * 2) + math.div( $kendo-chart-selection-handle-size, 1.5 );
    }

    .k-right-handle.k-handle-active div {
        margin-left: -($kendo-chart-selection-handle-size * 2);
        padding-right: ($kendo-chart-selection-handle-size * 2) + math.div( $kendo-chart-selection-handle-size, 1.5 );
    }

    .k-mask {
        position: absolute;
        height: 100%;
    }

    // Navigator hint
    .k-navigator-hint div {
        position: absolute;
    }

    .k-navigator-hint .k-scroll {
        @include border-radius( k-border-radius(md) );
        position: absolute;
        height: 4px;
    }

    .k-navigator-hint .k-tooltip {
        margin-top: 20px;
        min-width: 160px;
        opacity: 1;
        text-align: center;
    }

    // Sparklines
    .k-sparkline,
    .k-sparkline span {
        display: inline-block;
        vertical-align: top;
    }

    .k-sparkline span {
        height: 100%;
        width: 100%;
    }

    .k-chart-dragging {
        user-select: none;
    }

    .k-chart-donut-center {
        position: absolute;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 50%;
        text-align: center;

        border: 4px solid transparent;
        box-sizing: border-box;
    }

    .k-pdf-export {
        .k-chart,
        .k-sparkline,
        .k-stockchart {
            .k-animation-container {
                display: none;
            }
        }
    }

    // Chart Overlay
    .k-chart-overlay {
        margin: 0;
        width: 100%;
        height: if( --kendo-chart-computed-title-height, calc( 100% - var( --kendo-chart-computed-title-height, #{$kendo-chart-computed-title-height} ) ), 100% );
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        z-index: 10001;

        &-top {
            top: var( --kendo-chart-computed-title-height, #{$kendo-chart-computed-title-height} );
        }

        .k-no-data {
            min-height: auto;
            padding-block-start: $kendo-chart-no-data-padding-y;
            color: inherit;
            font-size: $kendo-chart-no-data-font-size;
            font-weight: $kendo-chart-no-data-font-weight;
        }
    }

    // Diagram
    .k-diagram {
        height: 600px;
    }

    .k-diagram .km-scroll-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .k-diagram .km-scroll-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .k-canvas-container {
        width: 100%;
        height: 100%;
    }


    // Sankey labels
    .k-sankey text {
        pointer-events: none;
    }

    // Treemap
    .k-treemap {
        height: 400px;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        outline: 0;
        font-family: $kendo-treemap-font-family;
        font-size: $kendo-treemap-font-size;
        line-height: $kendo-treemap-line-height;
        display: block;
        overflow: hidden;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;

        // Tile
        .k-treemap-tile {
            margin: -1px 0 0 -1px;
            height: 100%;
            box-sizing: border-box;
            border: 1px solid;
            border-color: inherit;
            color: inherit;
            background-color: inherit;
            overflow: hidden;
            position: absolute;
        }
        > .k-treemap-tile {
            position: relative;
        }

        // Title
        .k-treemap-title {
            padding-block: $kendo-treemap-padding-y;
            padding-inline: $kendo-treemap-padding-x;
            border-width: 0 0 1px;
            border-style: solid;
            border-color: inherit;
            font-size: var( --kendo-font-size, inherit );
            background-position: 0 0;
            background-repeat: repeat-x;
        }
        .k-treemap-title-vertical {
            padding-block: $kendo-treemap-padding-x;
            padding-inline: $kendo-treemap-padding-y;
            width: $kendo-line-height-em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: absolute;
            top: 0;
            bottom: 0;

            > div {
                transform-origin: right;
                transform: rotate(-90deg);
                position: absolute;
                top: 0;
                right: 1em;
            }
        }


        // Wrap
        .k-treemap-wrap {
            border-color: inherit;
            color: inherit;
            background-color: inherit;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
        .k-treemap-title + .k-treemap-wrap {
            top: calc( calc( #{$kendo-treemap-padding-y} * 2 ) + calc( var( --kendo-line-height, normal ) * var( --kendo-font-size, inherit) ) + 1px);
        }
        .k-treemap-title-vertical + .k-treemap-wrap {
            left: calc( calc( #{$kendo-treemap-padding-y} * 2 ) + calc( var( --kendo-line-height, normal ) * var( --kendo-font-size, inherit) ) + 1px);
        }


        // Leaf
        .k-leaf {
            padding: $kendo-treemap-padding-x;
        }
    }




    // Gauge
    .k-gauge {
        text-align: start;
        position: relative;
    }

    .k-arcgauge,
    .k-circulargauge {
        display: inline-block;
    }

    .k-arcgauge-label,
    .k-circulargauge-label {
        position: absolute;
        text-align: center;
        padding: 0;
        margin: 0;
    }


    // qrcode
    .k-qrcode {
        display: inline-block;

        > div {
            height: 150px;
        }
    }




    // export variables to allow use in scripts
    $exported: (
        primary: $kendo-color-primary,
        primary-contrast: $kendo-color-primary-contrast,
        base: $kendo-base-bg,
        background: $kendo-chart-bg,

        border-radius: k-border-radius(md),

        normal-background: $kendo-base-bg,
        normal-text-color: $kendo-base-text,
        hover-background: $kendo-hover-bg,
        hover-text-color: $kendo-hover-text,
        selected-background: $kendo-selected-bg,
        selected-text-color: $kendo-selected-text,

        success: $kendo-color-success,
        info: $kendo-color-info,
        warning: $kendo-color-warning,
        error: $kendo-color-error,

        series-a: $kendo-series-a,
        series-b: $kendo-series-b,
        series-c: $kendo-series-c,
        series-d: $kendo-series-d,
        series-e: $kendo-series-e,
        series-f: $kendo-series-f,

        series-1: $kendo-series-1,
        series-2: $kendo-series-2,
        series-3: $kendo-series-3,
        series-4: $kendo-series-4,
        series-5: $kendo-series-5,
        series-6: $kendo-series-6,
        series-7: $kendo-series-7,
        series-8: $kendo-series-8,
        series-9: $kendo-series-9,
        series-10: $kendo-series-10,
        series-11: $kendo-series-11,
        series-12: $kendo-series-12,
        series-13: $kendo-series-13,
        series-14: $kendo-series-14,
        series-15: $kendo-series-15,
        series-16: $kendo-series-16,
        series-17: $kendo-series-17,
        series-18: $kendo-series-18,
        series-19: $kendo-series-19,
        series-20: $kendo-series-20,
        series-21: $kendo-series-21,
        series-22: $kendo-series-22,
        series-23: $kendo-series-23,
        series-24: $kendo-series-24,
        series-25: $kendo-series-25,
        series-26: $kendo-series-26,
        series-27: $kendo-series-27,
        series-28: $kendo-series-28,
        series-29: $kendo-series-29,
        series-30: $kendo-series-30,

        gauge-pointer: $kendo-color-primary,
        gauge-track: if($kendo-enable-color-system, k-color( base-emphasis ), k-try-shade( $kendo-chart-bg ))
    );

    @each $name, $value in $exported {
        $type: meta.type-of($value);

        .k-var--#{$name} {
            @if $type == "color" or $type == "string" {
                // background-color can store any color
                background-color: $value;
            } @else if $type == "number" {
                // margin-top can store positive & negative values
                margin-top: $value;
            }
        }
    }

}


@mixin kendo-dataviz--layout() {
    @include kendo-dataviz--layout-base();
}
