@use "sass:math";
@use "../core/_index.scss" as *;
@use "./_variables.scss" as *;

@mixin kendo-draggable--layout-base() {

    // Layout
    .k-drag-clue {
        @include border-radius( $kendo-drag-hint-border-radius );
        padding-block: $kendo-drag-hint-padding-y;
        padding-inline: $kendo-drag-hint-padding-x;
        border-width: $kendo-drag-hint-border-width;
        border-style: if( $kendo-drag-hint-border-width == null, null, solid );
        box-sizing: border-box;
        font-size: $kendo-drag-hint-font-size;
        font-family: $kendo-drag-hint-font-family;
        line-height: $kendo-drag-hint-line-height;
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        align-content: center;
        white-space: nowrap;
        opacity: $kendo-drag-hint-opacity;
        overflow: hidden;
        position: absolute;
        z-index: 20000;
        cursor: move;
    }
    .k-drag-status,
    .k-drag-clue .k-drag-status {
        margin: 0;
        margin-right: .4ex;
    }


    // Drop hint
    .k-drop-hint {
        display: flex;
        gap: $kendo-drop-hint-arrow-spacing;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        z-index: 1000;
        transform: translate(-50%, -50%);
    }

    .k-drop-hint-start,
    .k-drop-hint-end {
        flex-shrink: 0;
        border-width: math.div( $kendo-drop-hint-arrow-size, 2 );
        border-style: solid;
        border-color: transparent;
    }

    .k-drop-hint-line {
        flex-grow: 1;
        background-color: $kendo-drop-hint-bg;
    }

    .k-drop-hint-h {
        .k-drop-hint-start {
            border-left-width: ( math.div( $kendo-drop-hint-arrow-size, 2 ) * $equilateral-index );
            border-left-color: $kendo-drop-hint-bg;
            border-right-width: 0;
        }

        .k-drop-hint-line {
            width: $kendo-drop-hint-line-h-width;
            height: $kendo-drop-hint-line-h-height;
        }

        .k-drop-hint-end {
            border-right-width: ( math.div( $kendo-drop-hint-arrow-size, 2 ) * $equilateral-index );
            border-right-color: $kendo-drop-hint-bg;
            border-left-width: 0;
        }
    }

    .k-drop-hint-v {
        flex-direction: column;

        .k-drop-hint-start {
            border-top-width: ( math.div( $kendo-drop-hint-arrow-size, 2 ) * $equilateral-index );
            border-top-color: $kendo-drop-hint-bg;
            border-bottom-width: 0;
        }

        .k-drop-hint-line {
            width: $kendo-drop-hint-line-v-width;
            height: $kendo-drop-hint-line-v-height;
        }

        .k-drop-hint-end {
            border-bottom-width: ( math.div( $kendo-drop-hint-arrow-size, 2 ) * $equilateral-index );
            border-bottom-color: $kendo-drop-hint-bg;
            border-top-width: 0;
        }
    }


    // Reorder cue
    .k-reorder-cue {
        position: absolute;

        &::before,
        &::after {
            content: "";
            width: 0;
            height: 0;
            border: 3px solid transparent;
            position: absolute;
            transform: translateX(-50%);
        }

        &::before {
            border-bottom-width: 0;
            border-top-color: currentColor;
            top: -4px;
        }
        &::after {
            border-top-width: 0;
            border-bottom-color: currentColor;
            bottom: -4px;
        }
    }

}


@mixin kendo-draggable--layout() {
    @include kendo-draggable--layout-base();
}
