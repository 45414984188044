$kendo-enable-typography: false !default;

$kendo-use-input-button-width: true !default;
$kendo-use-input-spinner-width: true !default;
$kendo-use-input-spinner-icon-offset: false !default;

$kendo-zindex-popup: 1 !default;
$kendo-zindex-window: 2 !default;

// TODO: refactor
/// Box shadow of focused items.
$kendo-focus-shadow: inset 0 0 0 2px rgba(0, 0, 0, .13) !default;

/// Transition used across all components.
$kendo-transition: color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out, box-shadow .2s ease-in-out !default;


