@use "../core/_index.scss" as *;

// Badge

/// The width of the border around the Badge.
/// @group badge
$kendo-badge-border-width: 1px !default;

/// The border radius of the Badge.
/// @group badge
$kendo-badge-border-radius: k-border-radius(md) !default;


/// The horizontal padding of the Badge.
/// @group badge
$kendo-badge-padding-x: k-spacing(1) !default;
/// The horizontal padding of the small Badge.
/// @group badge
$kendo-badge-sm-padding-x: k-spacing(0.5) !default;
/// The horizontal padding of the medium Badge.
/// @group badge
$kendo-badge-md-padding-x: k-spacing(1) !default;
/// The horizontal padding of the large Badge.
/// @group badge
$kendo-badge-lg-padding-x: k-spacing(1.5) !default;

/// The vertical padding of the Badge.
/// @group badge
$kendo-badge-padding-y: k-spacing(1) !default;
/// The vertical padding of the small Badge.
/// @group badge
$kendo-badge-sm-padding-y: k-spacing(0.5) !default;
/// The vertical padding of the medium Badge.
/// @group badge
$kendo-badge-md-padding-y: k-spacing(1) !default;
/// The vertical padding of the large Badge.
/// @group badge
$kendo-badge-lg-padding-y: k-spacing(1.5) !default;

/// The font sizes of the Badge.
/// @group badge
$kendo-badge-font-size: var( --kendo-font-size-xs, inherit ) !default;
/// The font size of the small Badge.
/// @group badge
$kendo-badge-sm-font-size: var( --kendo-font-size-xs, inherit ) !default;
/// The font size of the medium Badge.
/// @group badge
$kendo-badge-md-font-size: var( --kendo-font-size-xs, inherit )!default;
/// The font size of the large Badge.
/// @group badge
$kendo-badge-lg-font-size: var( --kendo-font-size-xs, inherit ) !default;

/// The line heights used along with the $kendo-font-size variable.
/// @group badge
$kendo-badge-line-height: 1 !default;
/// The line height used along with the $kendo-font-size variable of the small Badge.
/// @group badge
$kendo-badge-sm-line-height: $kendo-badge-line-height !default;
/// The line height used along with the $kendo-font-size variable of the medium Badge.
/// @group badge
$kendo-badge-md-line-height: $kendo-badge-line-height !default;
/// The line height used along with the $kendo-font-size variable of the large Badge.
/// @group badge
$kendo-badge-lg-line-height: $kendo-badge-line-height !default;

/// The calculated minimum width of the circular Badge.
/// @group badge
$kendo-badge-min-width: calc( #{$kendo-badge-line-height * 1em} + calc( #{$kendo-badge-padding-y} * 2 ) + calc( #{$kendo-badge-border-width} * 2 )) !default;
/// The calculated minimum width of the small circular Badge.
/// @group badge
$kendo-badge-sm-min-width: calc( #{$kendo-badge-sm-line-height * 1em} + calc( #{$kendo-badge-sm-padding-y} * 2 ) + calc( #{$kendo-badge-border-width} * 2 ) ) !default;
/// The calculated minimum width of the medium circular Badge.
/// @group badge
$kendo-badge-md-min-width: calc( #{$kendo-badge-md-line-height * 1em} + calc( #{$kendo-badge-md-padding-y} * 2 ) + calc( #{$kendo-badge-border-width} * 2 ) ) !default;
/// The calculated minimum width of the large circular Badge.
/// @group badge
$kendo-badge-lg-min-width: calc( #{$kendo-badge-lg-line-height * 1em} + calc( #{$kendo-badge-lg-padding-y} * 2 ) + calc( #{$kendo-badge-border-width} * 2 ) ) !default;

/// The sizes map for the Badge.
/// @group badge
$kendo-badge-sizes: (
    sm: (
        padding-x: $kendo-badge-sm-padding-x,
        padding-y: $kendo-badge-sm-padding-y,
        font-size: $kendo-badge-sm-font-size,
        line-height: $kendo-badge-sm-line-height,
        min-width: $kendo-badge-sm-min-width
    ),
    md: (
        padding-x: $kendo-badge-md-padding-x,
        padding-y: $kendo-badge-md-padding-y,
        font-size: $kendo-badge-md-font-size,
        line-height: $kendo-badge-md-line-height,
        min-width: $kendo-badge-md-min-width
    ),
    lg: (
        padding-x: $kendo-badge-lg-padding-x,
        padding-y: $kendo-badge-lg-padding-y,
        font-size: $kendo-badge-lg-font-size,
        line-height: $kendo-badge-lg-line-height,
        min-width: $kendo-badge-lg-min-width
    )
) !default;
