@use "../core/_index.scss" as *;
@use "./_variables.scss" as *;

@mixin kendo-loader--theme-base() {

    @each $name, $color in $kendo-theme-colors {
        .k-loader-#{$name} {
            color: $color;
        }
    }

    .k-loader-container-panel {
        background-color: $kendo-loader-container-panel-bg;
        border-color: $kendo-loader-container-panel-border-color;
    }

}


@mixin kendo-loader--theme() {
    @include kendo-loader--theme-base();
}
