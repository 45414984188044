@use "../core/_index.scss" as *;
@use "../core/functions/index.import.scss" as *;
@use "./_variables.scss" as *;

@mixin kendo-list--theme-base() {

    // List
    .k-list {
        @include fill(
            $kendo-list-text,
            $kendo-list-bg,
            $kendo-list-border
        );
    }


    // List header
    // .k-list-header,
    .k-list-group-sticky-header {
        @include fill(
            $kendo-list-header-text,
            $kendo-list-header-bg,
            $kendo-list-header-border,
        );
        @include box-shadow( $kendo-list-header-shadow );
    }


    // List item
    .k-list-item {
        @include fill(
            $kendo-list-item-text,
            $kendo-list-item-bg
        );

        // Hover
        &:hover,
        &.k-hover {
            @include fill(
                $kendo-list-item-hover-text,
                $kendo-list-item-hover-bg
            );
        }

        // Focus
        &:focus,
        &.k-focus {
            @include fill(
                $kendo-list-item-focus-text,
                $kendo-list-item-focus-bg
            );
            @include focus-indicator( $kendo-list-item-focus-shadow, true );
        }

        // Selected
        &.k-selected {
            @include fill(
                $kendo-list-item-selected-text,
                $kendo-list-item-selected-bg
            );
        }
        &.k-selected:hover,
        &.k-selected.k-hover {
            color: $kendo-list-item-selected-text;
            background-color: if($kendo-enable-color-system, k-color( primary-hover ), k-color-shade( $kendo-list-item-selected-bg ));
        }
    }

    // Option Label
    .k-list-optionlabel,
    .k-list-optionlabel.k-hover,
    .k-list-optionlabel:hover {
        color: $kendo-list-option-label-text;
    }

    // List group item
    .k-list-group-item {
        @include fill(
            $kendo-list-group-item-text,
            $kendo-list-group-item-bg,
            $kendo-list-group-item-border,
        );
        @include box-shadow( $kendo-list-group-item-shadow );
    }


    // List item group label
    .k-list-item-group-label {
        @include fill(
            $kendo-list-bg,
            $kendo-list-text
        );
    }

}


@mixin kendo-list--theme() {
    @include kendo-list--theme-base();
}
