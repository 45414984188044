.card {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  border-radius: $borderRadius;

  &:last-child {
    margin-bottom: 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 100px;
  }
}

.position-relative {
  position: relative;
}

.top-right {
  position: absolute;
  top: 2rem;
  right: 2rem;
}
