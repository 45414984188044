@use "../core/_index.scss" as *;
@use "./_variables.scss" as *;


@mixin kendo-listgroup--layout-base() {

    .k-listgroup {
        @include border-radius( $kendo-listgroup-border-radius );
        margin: 0;
        padding: 0;
        border-width: $kendo-listgroup-border-width;
        border-style: solid;
        font-size: $kendo-listgroup-font-size;
        line-height: $kendo-listgroup-line-height;
        list-style: none;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;

        > ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-direction: column;
        }

        // Borders of items
        .k-listgroup-item + .k-listgroup-item {
            border-top-width: $kendo-listgroup-item-border-width;
        }
    }
    .k-listgroup-flush {
        @include border-radius( 0 );
        border-left-width: 0;
        border-right-width: 0;
    }


    .k-listgroup-item {
        padding-block: $kendo-listgroup-item-padding-y;
        padding-inline: $kendo-listgroup-item-padding-x;
        border-width: 0;
        border-style: solid;
        border-color: inherit;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 0 0 auto;
        position: relative;

        > .k-link {
            margin-block: calc( #{$kendo-listgroup-item-padding-y} * -1 );
            margin-inline: calc( #{$kendo-listgroup-item-padding-x} * -1 );
            padding-block: $kendo-listgroup-item-padding-y;
            padding-inline: $kendo-listgroup-item-padding-x;
            color: inherit;
            text-decoration: none;
            outline: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex: 1 1 auto;
            position: relative;

            > .k-select {
                padding: $kendo-listgroup-item-padding-y;
                display: flex;
                align-items: center;
                flex: 0 0 auto;
                position: absolute;
                top: 50%;
                inset-inline-end: 0;
                transform: translateY(-50%);
            }
        }
    }
    .k-listgroup-item-segmented {
        align-items: stretch;

        > .k-link {
            margin-right: 0;
        }

        > .k-select {
            margin: calc( #{$kendo-listgroup-item-padding-y} * -1 ) calc( #{$kendo-listgroup-item-padding-x} * -1 ) calc( #{$kendo-listgroup-item-padding-y} * -1 ) 0;
            padding: $kendo-listgroup-item-padding-y;
            border-width: 0 0 0 1px;
            border-style: solid;
            border-color: inherit;
            cursor: pointer;
        }
    }


    // Forms in listgroup
    .k-listgroup-form-row {
        margin-block: calc( #{$kendo-listgroup-item-padding-y} * -1 );
        margin-inline: calc( #{$kendo-listgroup-item-padding-x} * -1 );
        padding-block: $kendo-listgroup-item-padding-y;
        padding-inline: $kendo-listgroup-item-padding-x;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex: 1 1 auto;
        position: relative;

        .k-listgroup-form-field-label {
            width: 40%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .k-listgroup-form-field-wrapper {
            width: 50%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            text-align: end;
            position: relative;

            select,
            input[type="text"],
            input[type="password"],
            input[type="date"],
            input[type="time"],
            input[type="datetime-local"],
            input[type="number"],
            textarea {
                width: 100%;
                box-sizing: border-box;
                flex: 1 1 100%;
            }

            textarea {
                min-height: 4em;
                resize: vertical;
            }
        }
    }
    .k-listgroup-item.k-listgroup-form-row {
        margin: 0;
    }


    [dir="rtl"] {

        .k-listgroup-item {

            > .k-link {

                > .k-select {
                    right: auto;
                    left: 0;
                }
            }
        }
        .k-listgroup-item-segmented {

            > .k-link {
                margin-right: calc( #{$kendo-listgroup-item-padding-x} * -1 );
                margin-left: 0;
            }

            > .k-select {
                margin-right: 0;
                margin-left: calc( #{$kendo-listgroup-item-padding-x} * -1 );
                border-right-width: 1px;
                border-left-width: 0;
            }
        }
    }


    // No flexbox fix
    .k-noflexbox {
        .k-listgroup,
        .k-listgroup > ul
        .k-listgroup-item,
        .k-listgroup-item > .k-link {
            display: block;
        }
    }

}


@mixin kendo-listgroup--layout() {
    @include kendo-listgroup--layout-base();
}
