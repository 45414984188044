.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 7rem 2rem 2rem 4rem;
  transition: margin-left $transitionDuration;
}

.layout-main {
  flex: 1 1 auto;
}

.layout-banner {
  position: fixed;
  z-index: 997;
  left: 0;
  top: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  background-color: var(--primary-600) !important;
  color: white;
}
