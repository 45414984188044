@use "../core/_index.scss" as *;
@use "./variables.scss" as *;

@mixin kendo-captcha--theme-base() {

    // Captcha
    .k-captcha {
        @include fill(
            $kendo-captcha-text,
            $kendo-captcha-bg,
            $kendo-captcha-border
        );
    }

}


@mixin kendo-captcha--theme() {
    @include kendo-captcha--theme-base();
}
