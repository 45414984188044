$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.scss";
@import "primeicons/primeicons.css";
// @import "primeng/resources/themes/bootstrap4-dark-purple/theme.css";
// @import "primeng/resources/primeng.css";

sup {
  color: red;
}

.custom-alert {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  font-family: "Arial", sans-serif;
  text-align: right;
  align-items: right;
  float: right;
  display: inline;
  width: 10%;
  margin-top: -45px;
}

.parent-container {
  display: flex;
  justify-content: flex-end;
}

.error-header {
  font-size: 12px;
}

.error-content {
  margin-top: 0;
  font-size: 14px;
}

.chat-button {
  position: fixed;
  bottom: 5.5rem;
  right: 2rem;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color) !important;
  color: white;
  font-size: 1.8rem;
  z-index: 9999;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  i {
    color: var(--primary-color-text);
  }
}

.chat-button:hover {
  background-color: var(--primary-color) !important;
}

.p-element .ng-trigger-animation .p-dialog.p-dynamic-dialog.p-component {
  height: 0 !important;
}

:root {
  --kendo-color-primary: var(--primary-color) !important;
  --kendo-color-primary-hover: var(--primary-color) !important;
  --kendo-color-primary-active: var(--primary-color) !important;
  --kendo-color-primary-emphasis: var(--primary-color) !important;
  --kendo-color-primary-on-surface: var(--primary-color) !important;

  --kendo-marquee-bg: var(--primary-color) !important;
  --kendo-marquee-border: var(--primary-color) !important;
  --kendo-text-selection-bg: var(--primary-color) !important;
}
