@use "sass:list";
@use "../core/_index.scss" as *;
@use "../list/_variables.scss" as *;
@use "../popup/_variables.scss" as *;
@use "../icons/_variables.scss" as *;

// Menu

/// The width of the border around the Menu.
/// @group menu
$kendo-menu-border-width: 0px !default;

/// The font family of the Menu.
/// @group menu
$kendo-menu-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Menu.
/// @group menu
$kendo-menu-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Menu used along with $kendo-font-size.
/// @group menu
$kendo-menu-line-height: var( --kendo-line-height, normal ) !default;

/// The background color of the Menu.
/// @group menu
$kendo-menu-bg: transparent !default;
/// The text color of the Menu.
/// @group menu
$kendo-menu-text: $kendo-component-text !default;
/// The border color of the Menu.
/// @group menu
$kendo-menu-border: null !default;
/// The background gradient of the Menu.
/// @group menu
$kendo-menu-gradient: null !default;

// Menu item

/// The horizontal padding of the Menu item.
/// @group menu
$kendo-menu-item-padding-x: k-spacing(3) !default;
/// The vertical padding of the Menu item.
/// @group menu
$kendo-menu-item-padding-y: k-spacing(1.5) !default;
/// The spacing between the Menu items.
/// @group menu
$kendo-menu-item-spacing: k-spacing(0) !default;
/// The spacing between the Menu item text and icons.
/// @group menu
$kendo-menu-item-icon-spacing: $kendo-icon-spacing !default;

/// The font weight of the selected Menu item.
/// @group menu
$kendo-menu-item-selected-font-weight: var( --kendo-font-weight-bold, normal ) !default;

/// The background color of the Menu item.
/// @group menu
$kendo-menu-item-bg: null !default;
/// The text color of the Menu item.
/// @group menu
$kendo-menu-item-text: $kendo-subtle-text !default;
/// The border color of the Menu item.
/// @group menu
$kendo-menu-item-border: null !default;
/// The background gradient of the Menu item.
/// @group menu
$kendo-menu-item-gradient: null !default;

/// The background color of hovered Menu item.
/// @group menu
$kendo-menu-item-hover-bg: null !default;
/// The text color of hovered Menu item.
/// @group menu
$kendo-menu-item-hover-text: $kendo-component-text !default;
/// The border color of hovered Menu item.
/// @group menu
$kendo-menu-item-hover-border: null !default;
/// The background gradient of hovered Menu item.
/// @group menu
$kendo-menu-item-hover-gradient: null !default;

/// The background color of active Menu item.
/// @group menu
$kendo-menu-item-active-bg: null !default;
/// The text color of active Menu item.
/// @group menu
$kendo-menu-item-active-text: $kendo-link-text !default;
/// The border color of active Menu item.
/// @group menu
$kendo-menu-item-active-border: null !default;
/// The background gradient of active Menu item.
/// @group menu
$kendo-menu-item-active-gradient: null !default;

/// The base shadow of focused Menu item.
/// @group menu
$kendo-menu-item-focus-shadow: $kendo-list-item-focus-shadow !default;

/// The background color of selected Menu item.
/// @group menu
$kendo-menu-item-selected-bg: null !default;
/// The text color of selected Menu item.
/// @group menu
$kendo-menu-item-selected-text: $kendo-link-text !default;
/// The border color of selected Menu item.
/// @group menu
$kendo-menu-item-selected-border: null !default;
/// The background gradient of selected Menu item.
/// @group menu
$kendo-menu-item-selected-gradient: null !default;

/// The background color of the Menu scroll buttons.
/// @group menu
$kendo-menu-scroll-button-bg: null !default;
/// The text color of the Menu scroll buttons.
/// @group menu
$kendo-menu-scroll-button-text: $kendo-subtle-text !default;
/// The border color of the Menu scroll buttons.
/// @group menu
$kendo-menu-scroll-button-border: null !default;
/// The background gradient of the Menu scroll buttons.
/// @group menu
$kendo-menu-scroll-button-gradient: null !default;

/// The background color of hovered Menu scroll buttons.
/// @group menu
$kendo-menu-scroll-button-hover-bg: null !default;
/// The text color of hovered Menu scroll buttons.
/// @group menu
$kendo-menu-scroll-button-hover-text: $kendo-component-text !default;
/// The border color of hovered Menu scroll buttons.
/// @group menu
$kendo-menu-scroll-button-hover-border: null !default;
/// The background gradient of hovered Menu scroll buttons.
/// @group menu
$kendo-menu-scroll-button-hover-gradient: null !default;


// Menu Separator

/// The inline margin of the horizontal Menu separator.
/// @group menu
$kendo-menu-separator-margin-inline: k-spacing(1) !default;
/// The block margin of the horizontal Menu separator.
/// @group menu
$kendo-menu-separator-margin-block: k-spacing(1.5) !default;
/// The border color of the Menu separator.
/// @group menu
$kendo-menu-separator-border: if($kendo-enable-color-system, k-color(border), $kendo-component-border) !default;


// Menu popup

/// The horizontal padding of the Menu popup.
/// @group menu
$kendo-menu-popup-padding-x: null !default;

/// The vertical padding of the Menu popup.
/// @group menu
$kendo-menu-popup-padding-y: null !default;

/// The width of the border around the Menu popup.
/// @group menu
$kendo-menu-popup-border-width: $kendo-popup-border-width !default;

/// The font sizes of the Menu popup.
/// @group menu
$kendo-menu-popup-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-menu-popup-sm-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-menu-popup-md-font-size: var( --kendo-font-size, inherit ) !default;
$kendo-menu-popup-lg-font-size: var( --kendo-font-size-lg, inherit ) !default;

/// The line heights used along with $kendo-font-size.
/// @group menu
$kendo-menu-popup-line-height: var( --kendo-line-height, normal ) !default;
$kendo-menu-popup-sm-line-height: var( --kendo-line-height, normal ) !default;
$kendo-menu-popup-md-line-height: var( --kendo-line-height, normal ) !default;
$kendo-menu-popup-lg-line-height: var( --kendo-line-height-lg, normal ) !default;

/// The background color of the Menu popup.
/// @group menu
$kendo-menu-popup-bg: $kendo-popup-bg !default;
/// The text color of the Menu popup.
/// @group menu
$kendo-menu-popup-text: $kendo-popup-text !default;
/// The border color of the Menu popup.
/// @group menu
$kendo-menu-popup-border: $kendo-popup-border !default;
/// The background gradient of the Menu popup.
/// @group menu
$kendo-menu-popup-gradient: null !default;


// Menu popup item

/// The horizontal padding of the Menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-x: k-spacing(2) !default;
$kendo-menu-popup-sm-item-padding-x: k-spacing(2) !default;
$kendo-menu-popup-md-item-padding-x: k-spacing(2) !default;
$kendo-menu-popup-lg-item-padding-x: k-spacing(2) !default;

/// The vertical padding of the Menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-y: k-spacing(1) !default;
$kendo-menu-popup-sm-item-padding-y: k-spacing(0.5) !default;
$kendo-menu-popup-md-item-padding-y: k-spacing(1) !default;
$kendo-menu-popup-lg-item-padding-y: k-spacing(1.5) !default;

/// The end padding of the Menu item in popup.
/// @group menu
$kendo-menu-popup-item-padding-end: calc( #{$kendo-menu-popup-item-padding-x} * 2 + #{$kendo-icon-size} ) !default;
$kendo-menu-popup-sm-item-padding-end: calc( #{$kendo-menu-popup-sm-item-padding-x} * 2 + #{$kendo-icon-size} ) !default;
$kendo-menu-popup-md-item-padding-end: calc( #{$kendo-menu-popup-md-item-padding-x} * 2 + #{$kendo-icon-size} ) !default;
$kendo-menu-popup-lg-item-padding-end: calc( #{$kendo-menu-popup-lg-item-padding-x} * 2 + #{$kendo-icon-size} ) !default;

/// The start margin of the Menu item expand icon.
/// @group menu
$kendo-menu-popup-sm-item-icon-margin-start: $kendo-menu-popup-sm-item-padding-x !default;
$kendo-menu-popup-md-item-icon-margin-start: $kendo-menu-popup-md-item-padding-x !default;
$kendo-menu-popup-lg-item-icon-margin-start: $kendo-menu-popup-lg-item-padding-x !default;

/// The end margin of the Menu item expand icon.
/// @group menu
$kendo-menu-popup-sm-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-sm-item-padding-end} - #{list.slash( $kendo-menu-popup-sm-item-padding-x, 2 )}) ) !default;
$kendo-menu-popup-md-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-md-item-padding-end} - #{list.slash( $kendo-menu-popup-md-item-padding-x, 2 )}) ) !default;
$kendo-menu-popup-lg-item-icon-margin-end: calc( -1 * (#{$kendo-menu-popup-lg-item-padding-end} - #{list.slash( $kendo-menu-popup-lg-item-padding-x, 2 )}) ) !default;

/// The spacing between the Menu items in popup.
/// @group menu
$kendo-menu-popup-item-spacing: k-spacing(0) !default;

/// The background color of the Menu item in popup.
/// @group menu
$kendo-menu-popup-item-bg: null !default;
/// The text color of the Menu item in popup.
/// @group menu
$kendo-menu-popup-item-text: null !default;
/// The border color of the Menu item in popup.
/// @group menu
$kendo-menu-popup-item-border: null !default;
/// The background gradient of the Menu item in popup.
/// @group menu
$kendo-menu-popup-item-gradient: null !default;

/// The background color of hovered Menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-bg: $kendo-list-item-hover-bg !default;
/// The text color of hovered Menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-text: $kendo-list-item-hover-text !default;
/// The border color of hovered Menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-border: null !default;
/// The background gradient of hovered Menu item in popup.
/// @group menu
$kendo-menu-popup-item-hover-gradient: null !default;

/// The background color of active Menu item in popup.
/// @group menu
$kendo-menu-popup-item-active-bg: $kendo-list-item-selected-bg !default;
/// The text color of active Menu item in popup.
/// @group menu
$kendo-menu-popup-item-active-text: $kendo-list-item-selected-text !default;
/// The border color of active Menu item in popup.
/// @group menu
$kendo-menu-popup-item-active-border: null !default;
/// The background gradient of active Menu item in popup.
/// @group menu
$kendo-menu-popup-item-active-gradient: null !default;

/// The base shadow of focused Menu item in popup.
/// @group menu
$kendo-menu-popup-item-focus-shadow: $kendo-menu-item-focus-shadow !default;
