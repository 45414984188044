@use "../core/_index.scss" as *;
@use "../icons/_variables.scss" as *;
@use "./variables.scss" as *;

@mixin kendo-panelbar--layout-base() {

    // Base
    .k-panelbar {
        margin: 0;
        padding: 0;
        border-width: $kendo-panelbar-border-width;
        border-style: $kendo-panelbar-border-style;
        box-sizing: border-box;
        outline: 0;
        font-family: $kendo-panelbar-font-family;
        font-size: $kendo-panelbar-font-size;
        line-height: $kendo-panelbar-line-height;
        list-style: none;
        display: block;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }


        // Root
        > .k-panelbar-header {
            // TODO
            border-width: 0;
            border-style: $kendo-panelbar-item-border-style;
            border-color: inherit;
            display: block;

            > .k-link {
                padding-block: $kendo-panelbar-header-padding-y;
                padding-inline: $kendo-panelbar-header-padding-x;
                color: inherit;
                background: none;
                text-decoration: none;
                font-weight: 500;
                display: flex;
                flex-flow: row nowrap;
                gap: $kendo-icon-spacing;
                align-items: center;
                align-content: center;
                position: relative;
                user-select: none;
                cursor: default;
                transition: $kendo-transition;
            }
        }
        > .k-panelbar-header + .k-panelbar-header {
            border-top-width: $kendo-panelbar-item-border-width;
        }


        // Sub
        .k-panelbar-group {
            margin: 0;
            padding: 0;
            border-width: 0;
            border-color: inherit;
            color: inherit;
            background-color: transparent;
            list-style: none;
        }
        .k-panelbar-group > .k-panelbar-item {
            display: block;

            > .k-link {
                padding-block: $kendo-panelbar-item-padding-y;
                padding-inline: $kendo-panelbar-item-padding-x;
                color: inherit;
                text-decoration: none;
                display: flex;
                flex-flow: row nowrap;
                gap: $kendo-icon-spacing;
                align-items: center;
                align-content: center;
                position: relative;
                user-select: none;
                cursor: default;
                transition: $kendo-transition;
            }

            // Hierarchy items
            @for $i from 1 through $kendo-panelbar-item-level-count {
                &.k-level-#{$i} {
                    .k-link {
                        padding-left: calc( #{$kendo-panelbar-item-padding-x} * #{$i} );
                    }
                }
            }
        }

        // Panelbar item
        .k-panelbar-item {
            outline-style: none;
        }

        // Panelbar content
        .k-panelbar-content {
            display: flow-root;
        }


        // Toggle icon
        .k-panelbar-expand,
        .k-panelbar-collapse,
        .k-panelbar-toggle {
            margin-inline-start: auto;
        }
        .k-panelbar-group .k-panelbar-expand,
        .k-panelbar-group .k-panelbar-collapse,
        .k-panelbar-group .k-panelbar-toggle {
            margin-inline-end: calc( #{$kendo-panelbar-header-padding-x} - #{$kendo-panelbar-item-padding-x} );
        }
    }

    .k-rtl .k-panelbar,
    .k-panelbar.k-rtl,
    .k-panelbar [dir = "rtl"] {
        .k-panelbar-group > .k-panelbar-item {
            // Hierarchy items
            @for $i from 1 through $kendo-panelbar-item-level-count {
                &.k-level-#{$i} {
                    .k-link {
                        padding-left: $kendo-panelbar-item-padding-x;
                        padding-right: calc( #{$kendo-panelbar-item-padding-x} * #{$i} );
                    }
                }
            }
        }
    }

}


@mixin kendo-panelbar--layout() {
    @include kendo-panelbar--layout-base();
}
