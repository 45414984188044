@use "../core/_index.scss" as *;
@use "../button/_variables.scss" as *;
@use "../menu/_variables.scss" as *;

// Toolbar

/// The horizontal padding of the Toolbar.
/// @group toolbar
$kendo-toolbar-padding-x: null !default;
/// The horizontal padding of the small Toolbar.
/// @group toolbar
$kendo-toolbar-sm-padding-x: k-spacing(1) !default;
/// The horizontal padding of the medium Toolbar.
/// @group toolbar
$kendo-toolbar-md-padding-x: k-spacing(2) !default;
/// The horizontal padding of the large Toolbar.
/// @group toolbar
$kendo-toolbar-lg-padding-x: k-spacing(2.5) !default;

/// The vertical padding of the Toolbar.
/// @group toolbar
$kendo-toolbar-padding-y: null !default;
/// The vertical padding of the small Toolbar.
/// @group toolbar
$kendo-toolbar-sm-padding-y: k-spacing(1) !default;
/// The vertical padding of the medium Toolbar.
/// @group toolbar
$kendo-toolbar-md-padding-y: k-spacing(2) !default;
/// The vertical padding of the large Toolbar.
/// @group toolbar
$kendo-toolbar-lg-padding-y: k-spacing(2.5) !default;

/// The spacing between the Toolbar tools.
/// @group toolbar
$kendo-toolbar-spacing: null !default;
/// The spacing between the tools of the small Toolbar.
/// @group toolbar
$kendo-toolbar-sm-spacing: k-spacing(1.5) !default;
/// The spacing between the tools of the medium Toolbar.
/// @group toolbar
$kendo-toolbar-md-spacing: k-spacing(2) !default;
/// The spacing between the tools of the large Toolbar.
/// @group toolbar
$kendo-toolbar-lg-spacing: k-spacing(2.5) !default;

/// The width of the border around the Toolbar.
/// @group toolbar
$kendo-toolbar-border-width: 1px !default;
/// The border radius of the Toolbar.
/// @group toolbar
$kendo-toolbar-border-radius: null !default;

/// The font family of the Toolbar.
/// @group toolbar
$kendo-toolbar-font-family: var( --kendo-font-family, inherit ) !default;
/// The font size of the Toolbar.
/// @group toolbar
$kendo-toolbar-font-size: var( --kendo-font-size, inherit ) !default;
/// The line height of the Toolbar.
/// @group toolbar
$kendo-toolbar-line-height: var( --kendo-line-height, normal ) !default;

/// The background color of the Toolbar.
/// @group toolbar
$kendo-toolbar-bg: $kendo-base-bg !default;
/// The text color of the Toolbar.
/// @group toolbar
$kendo-toolbar-text: $kendo-base-text !default;
/// The color of the border around the Toolbar.
/// @group toolbar
$kendo-toolbar-border: $kendo-base-border !default;
/// The gradient of the Toolbar.
/// @group toolbar
$kendo-toolbar-gradient: null !default;
/// The box shadow of the Toolbar.
/// @group toolbar
$kendo-toolbar-shadow: null !default;

/// The text color of the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-text: $kendo-button-text !default;
/// The color of the border around the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-border: if($kendo-enable-color-system, color-mix(in srgb, k-color( on-base ) 50%, transparent), rgba( $kendo-toolbar-outline-text, .5)) !default;
/// The gradient of the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-gradient: null !default;
/// The box shadow of the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-shadow: null !default;
/// The width of the border around the outline Toolbar.
/// @group toolbar
$kendo-toolbar-outline-border-width: $kendo-toolbar-border-width !default;

/// The text color of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-text: null !default;
/// The color of the border around the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-border: $kendo-toolbar-border !default;
/// The gradient of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-gradient: null !default;
/// The box shadow of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-shadow: null !default;
/// Border width of the flat Toolbar.
/// @group toolbar
$kendo-toolbar-flat-border-width: 1px !default;

/// The color of the separator border of the Toolbar.
/// @group toolbar
$kendo-toolbar-separator-border: inherit !default;

/// The width of the input in the Toolbar.
/// @group toolbar
$kendo-toolbar-input-width: 10em !default;

/// The box shadow of the focused Toolbar item.
/// @group toolbar
$kendo-toolbar-item-shadow: $kendo-button-focus-shadow !default;

/// The font size of the Toolbar overflow container.
/// @group toolbar
$kendo-toolbar-overflow-container-font-size: $kendo-menu-popup-md-font-size !default;
/// The line-height of the Toolbar overflow container.
/// @group toolbar
$kendo-toolbar-overflow-container-line-height: $kendo-menu-popup-md-line-height !default;
/// The vertical padding of the Toolbar overflow button.
/// @group toolbar
$kendo-toolbar-overflow-button-padding-y: $kendo-menu-popup-md-item-padding-y !default;
/// The horizontal padding of the Toolbar overflow button.
/// @group toolbar
$kendo-toolbar-overflow-button-padding-x: $kendo-menu-popup-md-item-padding-x !default;

/// The sizes map for the Toolbar.
/// @group toolbar
$kendo-toolbar-sizes: (
    sm: (
        padding-x: $kendo-toolbar-sm-padding-x,
        padding-y: $kendo-toolbar-sm-padding-y,
        spacing: $kendo-toolbar-sm-spacing
    ),
    md: (
        padding-x: $kendo-toolbar-md-padding-x,
        padding-y: $kendo-toolbar-md-padding-y,
        spacing: $kendo-toolbar-md-spacing
    ),
    lg: (
        padding-x: $kendo-toolbar-lg-padding-x,
        padding-y: $kendo-toolbar-lg-padding-y,
        spacing: $kendo-toolbar-lg-spacing
    )
) !default;
