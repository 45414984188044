@use "../core/_index.scss" as *;
@use "./variables.scss" as *;
@use "../menu/_variables.scss" as *;

@mixin kendo-toolbar--theme-base() {

    // Theme
    .k-toolbar {
        // Separator
        .k-separator,
        .k-toolbar-separator {
            border-color: $kendo-toolbar-separator-border;
        }


        // Template item
        .k-toolbar-item:focus,
        .k-toolbar-item.k-focus {
            @include focus-indicator( $kendo-toolbar-item-shadow );
        }


        // Button group
        .k-button-group {}

    }

    // Solid Toolbar
    .k-toolbar-solid {
        @include fill(
            $kendo-toolbar-text,
            $kendo-toolbar-bg,
            $kendo-toolbar-border,
            $kendo-toolbar-gradient
        );
        @include box-shadow( $kendo-toolbar-shadow );
    }

    // Outline Toolbar
    .k-toolbar-outline {
        @include fill(
            $color: $kendo-toolbar-outline-text,
            $border: $kendo-toolbar-outline-border
        );
        @include box-shadow( $kendo-toolbar-outline-shadow );
    }

    // Flat Toolbar
    .k-toolbar-flat {
        @include fill(
            $color: $kendo-toolbar-flat-text,
            $border: $kendo-toolbar-flat-border
        );
        @include box-shadow( $kendo-toolbar-flat-shadow );

        > .k-separator {
            border-inline-color: $kendo-toolbar-flat-border;
        }
    }

    .k-floating-toolbar,
    .editorToolbarWindow.k-window-content { // stylelint-disable-line
        @include fill(
            $kendo-toolbar-text,
            $kendo-toolbar-bg,
            $kendo-toolbar-border,
            $kendo-toolbar-gradient
        );
    }

    // Overflow container
    .k-overflow-container {

        .k-button {

            // Hover state
            &:hover,
            &.k-hover {
                color: $kendo-menu-popup-item-hover-text;
                background: $kendo-menu-popup-item-hover-bg;
            }

            // Button focus state
            &:focus,
            &.k-focus {
                @include focus-indicator( $kendo-menu-popup-item-focus-shadow, true );
            }

            // Active state
            &:active,
            &.k-active {
                color: $kendo-menu-popup-item-hover-text;
                background: $kendo-menu-popup-item-hover-bg;
            }

            // Selected
            &.k-selected {
                color: $kendo-menu-popup-item-active-text;
                background: $kendo-menu-popup-item-active-bg;
            }

            // Disabled state
            &:disabled,
            &.k-disabled {
                color: inherit;
            }
        }

    }

}


@mixin kendo-toolbar--theme() {
    @include kendo-toolbar--theme-base();
}
