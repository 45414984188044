@use "../core/_index.scss" as *;
@use "./variables.scss" as *;

@mixin kendo-orgchart--theme-base() {

    // OrgChart
    .k-orgchart {
        @include fill (
            $kendo-orgchart-text,
            $kendo-orgchart-bg,
            $kendo-orgchart-border
        );
    }

    // Card
    .k-orgchart-card {
        @include box-shadow( $kendo-orgchart-card-shadow );

        .k-card-body {
            @include fill( $border: $kendo-orgchart-card-body-border-color );
        }

        &:focus,
        &.k-focus {
            @include focus-indicator( $kendo-orgchart-card-focus-shadow );
        }
    }

    // Group
    .k-orgchart-node-group-container {
        @include fill (
            $kendo-orgchart-node-group-text,
            $kendo-orgchart-node-group-bg,
            $kendo-orgchart-node-group-border
        );
    }
    .k-orgchart-node-group-container:focus,
    .k-orgchart-node-group-container.k-focus {
        @include focus-indicator( $kendo-orgchart-node-group-focus-shadow );
        @include fill ( $border: $kendo-orgchart-node-group-focus-border );
    }

    .k-orgchart-node-group-subtitle {
        @include fill( $color: $kendo-orgchart-node-group-subtitle-text );
    }

    // Lines
    .k-orgchart-line-h,
    .k-orgchart-line-v {
        @include fill( $color: $kendo-orgchart-line-fill );
    }

}


@mixin kendo-orgchart--theme() {
    @include kendo-orgchart--theme-base();
}
