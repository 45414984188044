@use "../core/_index.scss" as *;
@use "./variables.scss" as *;

@mixin kendo-color-palette--layout-base() {

    // ColorPalette
    .k-colorpalette {
        border-width: 0;
        box-sizing: border-box;
        outline: 0;
        font-size: $kendo-color-palette-font-size;
        font-family: $kendo-color-palette-font-family;
        line-height: $kendo-color-palette-line-height;
        display: inline-flex;
        position: relative;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: $kendo-color-rgba-transparent;

        *,
        *::before,
        *::after {
            box-sizing: border-box;
        }
    }

    // Table
    .k-colorpalette-table {
        width: 100%;
        height: 100%;
        border-collapse: collapse;
        position: relative;
    }

    // Tile
    .k-colorpalette-tile {
        width: $kendo-color-palette-tile-width;
        height: $kendo-color-palette-tile-height;
        box-sizing: border-box;
        overflow: hidden;
        cursor: pointer;

        &:hover,
        &.k-hover {
            position: relative;
            z-index: 101;
        }

        &:focus,
        &.k-focus,
        &.k-selected,
        &.k-selected:hover {
            position: relative;
            z-index: 100;
        }
    }

}


@mixin kendo-color-palette--layout() {
    @include kendo-color-palette--layout-base();
}
