@use "sass:map";
@use "./_palettes.scss" as *;
@use "../_variables.scss" as *;

// Colors
$_default-colors: (
    // Misc
    app-surface: map.get( $kendo-palette-gray, "white" ),
    on-app-surface: map.get( $kendo-palette-gray, 12 ),
    subtle: map.get( $kendo-palette-gray, 10 ),
    surface: map.get( $kendo-palette-gray, 1 ),
    surface-alt: map.get( $kendo-palette-gray, "white" ),
    border: rgba( map.get( $kendo-palette-gray, "black" ), .08),
    border-alt: rgba( map.get( $kendo-palette-gray, "black" ), .16),
    // Base
    base-subtle: map.get( $kendo-palette-gray, 3 ),
    base-subtle-hover: map.get( $kendo-palette-gray, 4 ),
    base-subtle-active: map.get( $kendo-palette-gray, 5 ),
    base: map.get( $kendo-palette-gray, 2 ),
    base-hover: map.get( $kendo-palette-gray, 3 ),
    base-active: map.get( $kendo-palette-gray, 5 ),
    base-emphasis: map.get( $kendo-palette-gray, 6 ),
    base-on-subtle: map.get( $kendo-palette-gray, 12 ),
    on-base: map.get( $kendo-palette-gray, 12 ),
    base-on-surface: map.get( $kendo-palette-gray, 12 ),
    // Primary
    primary-subtle: map.get( $kendo-palette-coral, 2 ),
    primary-subtle-hover: map.get( $kendo-palette-coral, 3 ),
    primary-subtle-active: map.get( $kendo-palette-coral, 4 ),
    primary: map.get( $kendo-palette-coral, 9 ),
    primary-hover: map.get( $kendo-palette-coral, 10 ),
    primary-active: map.get( $kendo-palette-coral, 11 ),
    primary-emphasis: map.get( $kendo-palette-coral, 6 ),
    primary-on-subtle: map.get( $kendo-palette-coral, 15 ),
    on-primary: map.get( $kendo-palette-gray, "white" ),
    primary-on-surface: map.get( $kendo-palette-coral, 9 ),
    // Secondary
    secondary-subtle: map.get( $kendo-palette-gray, 1 ),
    secondary-subtle-hover: map.get( $kendo-palette-gray, 2 ),
    secondary-subtle-active: map.get( $kendo-palette-gray, 3 ),
    secondary: map.get( $kendo-palette-gray, 10 ),
    secondary-hover: map.get( $kendo-palette-gray, 11 ),
    secondary-active: map.get( $kendo-palette-gray, 12 ),
    secondary-emphasis: map.get( $kendo-palette-gray, 4 ),
    secondary-on-subtle: map.get( $kendo-palette-gray, 15 ),
    on-secondary: map.get( $kendo-palette-gray, "white" ),
    secondary-on-surface: map.get( $kendo-palette-gray, 13 ),
    // Tertiary
    tertiary-subtle: map.get( $kendo-palette-sky-blue, 2 ),
    tertiary-subtle-hover: map.get( $kendo-palette-sky-blue, 3 ),
    tertiary-subtle-active: map.get( $kendo-palette-sky-blue, 4 ),
    tertiary: map.get( $kendo-palette-sky-blue, 9 ),
    tertiary-hover: map.get( $kendo-palette-sky-blue, 10 ),
    tertiary-active: map.get( $kendo-palette-sky-blue, 11 ),
    tertiary-emphasis: map.get( $kendo-palette-sky-blue, 6 ),
    tertiary-on-subtle: map.get( $kendo-palette-sky-blue, 15 ),
    on-tertiary: map.get( $kendo-palette-gray, "white" ),
    tertiary-on-surface: map.get( $kendo-palette-sky-blue, 11 ),
    // Info
    info-subtle: map.get( $kendo-palette-blue, 2 ),
    info-subtle-hover: map.get( $kendo-palette-blue, 3 ),
    info-subtle-active: map.get( $kendo-palette-blue, 5 ),
    info: map.get( $kendo-palette-blue, 9 ),
    info-hover: map.get( $kendo-palette-blue, 10 ),
    info-active: map.get( $kendo-palette-blue, 11 ),
    info-emphasis: map.get( $kendo-palette-blue, 6 ),
    info-on-subtle: map.get( $kendo-palette-blue, 15 ),
    on-info: map.get( $kendo-palette-gray, "white" ),
    info-on-surface: map.get( $kendo-palette-blue, 11 ),
    // Success
    success-subtle: map.get( $kendo-palette-green, 2 ),
    success-subtle-hover: map.get( $kendo-palette-green, 3 ),
    success-subtle-active: map.get( $kendo-palette-green, 4 ),
    success: map.get( $kendo-palette-green, 9 ),
    success-hover: map.get( $kendo-palette-green, 10 ),
    success-active: map.get( $kendo-palette-green, 11 ),
    success-emphasis: map.get( $kendo-palette-green, 6 ),
    success-on-subtle: map.get( $kendo-palette-green, 15 ),
    on-success: map.get( $kendo-palette-gray, "white" ),
    success-on-surface: map.get( $kendo-palette-green, 11 ),
    // Warning
    warning-subtle: map.get( $kendo-palette-yellow, 2 ),
    warning-subtle-hover: map.get( $kendo-palette-yellow, 3 ),
    warning-subtle-active: map.get( $kendo-palette-yellow, 4 ),
    warning: map.get( $kendo-palette-yellow, 9 ),
    warning-hover: map.get( $kendo-palette-yellow, 10 ),
    warning-active: map.get( $kendo-palette-yellow, 11 ),
    warning-emphasis: map.get( $kendo-palette-yellow, 6 ),
    warning-on-subtle: map.get( $kendo-palette-yellow, 15 ),
    on-warning: map.get( $kendo-palette-gray, 12 ),
    warning-on-surface: map.get( $kendo-palette-yellow, 9 ),
    // Error
    error-subtle: map.get( $kendo-palette-red, 2 ),
    error-subtle-hover: map.get( $kendo-palette-red, 3 ),
    error-subtle-active: map.get( $kendo-palette-red, 5 ),
    error: map.get( $kendo-palette-red, 9 ),
    error-hover: map.get( $kendo-palette-red, 10 ),
    error-active: map.get( $kendo-palette-red, 11 ),
    error-emphasis: map.get( $kendo-palette-red, 6 ),
    error-on-subtle: map.get( $kendo-palette-red, 15 ),
    on-error: map.get( $kendo-palette-gray, "white" ),
    error-on-surface: map.get( $kendo-palette-red, 11 ),
    // Light
    light-subtle: map.get( $kendo-palette-gray, 1 ),
    light-subtle-hover: map.get( $kendo-palette-gray, 2 ),
    light-subtle-active: map.get( $kendo-palette-gray, 3 ),
    light: map.get( $kendo-palette-gray, 3 ),
    light-hover: map.get( $kendo-palette-gray, 4 ),
    light-active: map.get( $kendo-palette-gray, 5 ),
    light-emphasis: map.get( $kendo-palette-gray, 5 ),
    light-on-subtle: map.get( $kendo-palette-gray, 15 ),
    on-light: map.get( $kendo-palette-gray, "black" ),
    light-on-surface: map.get( $kendo-palette-gray, 4 ),
    // Dark
    dark-subtle: map.get( $kendo-palette-gray, 6 ),
    dark-subtle-hover: map.get( $kendo-palette-gray, 7 ),
    dark-subtle-active: map.get( $kendo-palette-gray, 8 ),
    dark: map.get( $kendo-palette-gray, 12 ),
    dark-hover: map.get( $kendo-palette-gray, 13 ),
    dark-active: map.get( $kendo-palette-gray, 14 ),
    dark-emphasis: map.get( $kendo-palette-gray, 10 ),
    dark-on-subtle: map.get( $kendo-palette-gray, 14 ),
    on-dark: map.get( $kendo-palette-gray, "white" ),
    dark-on-surface: map.get( $kendo-palette-gray, 15 ),
    // Inverse
    inverse-subtle: map.get( $kendo-palette-gray, 6 ),
    inverse-subtle-hover: map.get( $kendo-palette-gray, 7 ),
    inverse-subtle-active: map.get( $kendo-palette-gray, 8 ),
    inverse: map.get( $kendo-palette-gray, 12 ),
    inverse-hover: map.get( $kendo-palette-gray, 13 ),
    inverse-active: map.get( $kendo-palette-gray, 14 ),
    inverse-emphasis: map.get( $kendo-palette-gray, 10 ),
    inverse-on-subtle: map.get( $kendo-palette-gray, 14 ),
    on-inverse: map.get( $kendo-palette-gray, "white" ),
    inverse-on-surface: map.get( $kendo-palette-gray, 15 ),
    // Series A
    series-a: map.get( $kendo-palette-coral, 9 ),
    series-a-bold: map.get( $kendo-palette-coral, 12 ),
    series-a-bolder: map.get( $kendo-palette-coral, 14 ),
    series-a-subtle: map.get( $kendo-palette-coral, 5 ),
    series-a-subtler: map.get( $kendo-palette-coral, 7 ),
    // Series B
    series-b: map.get( $kendo-palette-lemon-yellow, 9 ),
    series-b-bold: map.get( $kendo-palette-lemon-yellow, 12 ),
    series-b-bolder: map.get( $kendo-palette-lemon-yellow, 14 ),
    series-b-subtle: map.get( $kendo-palette-lemon-yellow, 5 ),
    series-b-subtler: map.get( $kendo-palette-lemon-yellow, 7 ),
    // Series C
    series-c: map.get( $kendo-palette-spring-green, 9 ),
    series-c-bold: map.get( $kendo-palette-spring-green, 12 ),
    series-c-bolder: map.get( $kendo-palette-spring-green, 14 ),
    series-c-subtle: map.get( $kendo-palette-spring-green, 5 ),
    series-c-subtler: map.get( $kendo-palette-spring-green, 7 ),
    // Series D
    series-d: map.get( $kendo-palette-royal-blue, 9 ),
    series-d-bold: map.get( $kendo-palette-royal-blue, 12 ),
    series-d-bolder: map.get( $kendo-palette-royal-blue, 14 ),
    series-d-subtle: map.get( $kendo-palette-royal-blue, 5 ),
    series-d-subtler: map.get( $kendo-palette-royal-blue, 7 ),
    // Series Е
    series-e: map.get( $kendo-palette-lavender-purple, 9 ),
    series-e-bold: map.get( $kendo-palette-lavender-purple, 12 ),
    series-e-bolder: map.get( $kendo-palette-lavender-purple, 14 ),
    series-e-subtle: map.get( $kendo-palette-lavender-purple, 5 ),
    series-e-subtler: map.get( $kendo-palette-lavender-purple, 7 ),
    // Series F
    series-f: map.get( $kendo-palette-flamingo-pink, 9 ),
    series-f-bold: map.get( $kendo-palette-flamingo-pink, 12 ),
    series-f-bolder: map.get( $kendo-palette-flamingo-pink, 14 ),
    series-f-subtle: map.get( $kendo-palette-flamingo-pink, 5 ),
    series-f-subtler: map.get( $kendo-palette-flamingo-pink, 7 ),
) !default;

/// The global default Colors map.
/// @group color-system
$kendo-colors: $_default-colors !default;

$kendo-colors: map.merge($_default-colors, $kendo-colors);

@mixin kendo-color-system--styles() {
    @if ($kendo-enable-color-system) {
        :root {
            @each $key, $value in $kendo-colors {
                @if($value) {
                    --kendo-color-#{$key}: #{$value};
                }
            }
        }
    }
}
